import error from '../../assets/svgs/error.svg'
import headerStyles from '../Header/styles.module.css'
import leaderboardStyles from '../LeaderBoard/styles.module.css'
import styles from '../SmallScreenError/styles.module.css'
import blazepod_new from '../../assets/svgs/blazepod-big.svg'


export function SmallScreenError() {
  return (
    <>
    <div className={headerStyles.header}>
      <div className={styles.logoContainer1}>
        <img className={styles.blazepodIcon} alt='BlazePod logo' src={blazepod_new} />
      </div>
    </div>
    <div className={leaderboardStyles.leaderboards}>
        <img className={styles.errorsHazzard1} alt="Error logo" src={error} />
        <div className={styles.leaderboardsInner}>
            <i className={styles.trainingExcellence}>Web Leaderboards are not compatible with small screens yet.</i>
            <div className={styles.trainingExcellence1}>This device is too small to show our web leaderboards correctly. Please open this link on a larger screen such as desktop or TV or head into your leaderboard inside your app.</div>
        </div>
    </div>
    </>
    );
}
