import { useEffect, useRef } from "react";
import styles from "../styles.module.css";

import { PlayerName } from "../../PlayerName";
import { ScoreResult } from "../../ScoreResult";
import { getAnimationDelay } from "./TableRowHelper";
import { TableItemProps } from "./MakeHeaderRow";

export interface MakeRowProps extends TableItemProps {
  index: number;
}

export function MakeRow(props: MakeRowProps) {
  const {
    applyAnimation,
    item,
    marks,
    animationConfiguration,
    index,
  } = props;
  const elementRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const element = elementRef.current;
    if (element) {
      element.style.animation = applyAnimation ? animationConfiguration : '';
      element.style.animationDelay = applyAnimation ? getAnimationDelay(index) : '';
    }
  }, [animationConfiguration, applyAnimation, index]);

  return (
    <div className={styles.leaderboard1} ref={elementRef}>
      <div className={styles.leaderboardGeneralRow}>
        <div className={styles.rank}>{index + 1}</div>
        <div className={styles.generalRow}>
          <PlayerName name={item.name} />
          <ScoreResult marks={marks} item={item} />
        </div>
      </div>
    </div>
  );
}
