import { ScoreMarkEnum } from "../../api/enums/leaderboard-enums";

function formatMilisecToMMSSMSMS(milliseconds: any) {
  const value = typeof(milliseconds) == 'string' ? Number.parseInt(milliseconds) : milliseconds;
  const ms = Math.floor((value % 1000) / 10);
  const seconds = Math.floor((value / 1000) % 60);
  const minutes = Math.floor((value / 1000 / 60) % 60);

  return [
      minutes.toString().padStart(2, "0"),
      seconds.toString().padStart(2, "0"),
      ms.toString().padStart(2, "0")
  ].join(":");
}

function toMilisec(milliseconds: any) {
  const value = Number.parseFloat(milliseconds)/ 1000;
  return value.toFixed(2)
}

export function formatResult(mark: ScoreMarkEnum | string, milliseconds: any) : string{
  let result = milliseconds;
  const value = typeof(milliseconds) == 'string' ? Number.parseInt(milliseconds) : milliseconds;
  if (mark === ScoreMarkEnum.DURATION) {
    result = formatMilisecToMMSSMSMS(value)
  } else if (mark === ScoreMarkEnum.TOTAL_REACTION_TIMES) {
    result = toMilisec(value)
  }
  return result?.toLocaleString();
}

