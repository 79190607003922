import { useEffect, useMemo, useState } from "react";
import { ScoreMarkEnum } from "../../api/enums/leaderboard-enums";
import { BPLeaderboardItem } from "../../api/interfaces/BPleaderboard-item.interface";
import styles from "../TableData/styles.module.css";
import { MakeHeaderRow } from "./components/MakeHeaderRow";
import { MakeRow } from "./components/MakeRow";
import { MakeEmptyRow } from "./components/MakeEmptyRow";


interface TableDataProps {
  size: number;
  items: BPLeaderboardItem[];
  marks: ScoreMarkEnum[];
}

export function TableData(props: TableDataProps) {
  const animationConfiguration = `${styles.slideOutAndIn} 1.5s ease-in-out forwards`;
  const animationTimeout = 10000;
  const { size, items, marks } = props;

  const [applyAnimation, setApplyAnimation] = useState(false);

  useEffect(() => {
    setApplyAnimation(true);
    setTimeout(() => {
      setApplyAnimation(false);
    }, animationTimeout);
  }, [props]);

  const emptyRowCount = useMemo(() => {
    return size - items.length;
  }, [items.length, size]);

  return (
    <div className={styles.table}>
      {items.map((item, index) => {
        if (index === 0) {
          return (
            <MakeHeaderRow
              key={"header-row"}
              applyAnimation={applyAnimation}
              item={item}
              marks={marks}
              animationConfiguration={animationConfiguration}
            ></MakeHeaderRow>
          );
        } else {
          return (
            <MakeRow
              key={`${index}_general_row`}
              animationConfiguration={animationConfiguration}
              applyAnimation={applyAnimation}
              item={item}
              marks={marks}
              index={index}
            ></MakeRow>
          );
        }
      })}
      {emptyRowCount > 0 &&
        [...Array(emptyRowCount)].map((irr, index) => {
          const rank = index + 1 + items.length;
          return (
            <MakeEmptyRow
              key={`${rank}_empty_row`}
              rank={rank}
              applyAnimation={applyAnimation}
              marks={marks}
              animationConfiguration={animationConfiguration}
            ></MakeEmptyRow>
          );
        })}
    </div>
  );
}
