import { useMemo } from 'react';

import { ScoreMarkEnum } from '../../api/enums/leaderboard-enums';
import blazepod_grey from '../../assets/svgs/bp_Icon2.svg';
import medal from '../../assets/svgs/Empty state_Leaderboard4.png';
import { EmptyLeaderboard } from '../EmptyLeaderboard';
import styles from '../LeaderBoard/styles.module.css';
import { TableData } from '../TableData';
import { TableHeaders } from '../TableHeaders';

export function LeaderBoard(props: any) {
  const { payload, status } = props;
  let marks;

  const isGoAntNotEmpty = useMemo(() => {
    return payload?.isGo && payload?.items?.length > 0;
  }, [payload]);

  const isEmpty = useMemo(() => {
    return payload && payload?.items?.length < 1;
  }, [payload]);

  if (status.code > 400) {
    return (
      <div className={styles.leaderboards}>
        <EmptyLeaderboard></EmptyLeaderboard>
      </div>
    );
  } else {
    marks = payload.marks;
    if (!marks) {
      marks = new Array<ScoreMarkEnum>(
        ScoreMarkEnum.HITS,
        ScoreMarkEnum.TIMEOUT_MISSES,
        ScoreMarkEnum.REACTION_TIME,
        ScoreMarkEnum.STRIKES
      );
    }
    if (!marks.includes('timed_playes')) marks.push('timed_playes');

    if (!marks.includes('empty_row') && marks.length < 3) {
      marks.push('empty_row');
    }
  }
  return (
    <div className={styles.leaderboards}>
      <img className={styles.icon} alt='BlazePod logo' src={blazepod_grey} />
      <TableHeaders {...props} marks={marks}></TableHeaders>
      <TableData {...payload} marks={marks}></TableData>
      {isEmpty && (
        <div className={styles.blur}>
          <div className={styles.emptyStateLeaderboardParent}>
            <div className={styles.iconContainer}>
              <img className={styles.emptyStateIcon} alt='No result logo' src={medal} />
            </div>
            <b className={styles.mainText}>No results yet</b>
            <div className={styles.text}>
              When the first player will complete the activity, you'll be able
              to see the results updated live right here!
            </div>
          </div>
        </div>
      )}
      {isGoAntNotEmpty && (
        <div className={styles.emptyStateContainer}>
          <div className={styles.bluerSpace}></div>
          <div className={styles.blurWithGradient}>
            <div className={styles.emptyStateLeaderboardParent}>
              <b className={styles.mainText}>
                It's a little lonely here, isn't it?
              </b>
              <div className={styles.text}>
                Become a Pro to Complete this activity with multiple players and
                create a competitive leaderboard.
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
