import { useEffect, useRef } from "react";
import styles from "../styles.module.css";

import { PlayerName } from "../../PlayerName";
import { ScoreResult } from "../../ScoreResult";
import { ApplyAnimationProps, getAnimationDelay } from "./TableRowHelper";

interface MakeEmptyRowProps extends ApplyAnimationProps {
  rank: number;
}

export function MakeEmptyRow(props: MakeEmptyRowProps) {
  const { applyAnimation, animationConfiguration, rank } =
    props;
  const elementRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const element = elementRef.current;
    if (element) {
      element.style.animation = applyAnimation ? animationConfiguration : '';
      element.style.animationDelay = applyAnimation ? getAnimationDelay(rank) : '';
    }
  }, [animationConfiguration, applyAnimation, rank]);

  return (
    <div className={styles.leaderboardEmpty} ref={elementRef}>
      <div className={styles.leaderboardGeneralRow}>
        <div className={styles.rank}>{rank}</div>
        <div className={styles.generalRow}>
          <PlayerName name={""} />
          <ScoreResult marks={props.marks} emptyString="-" />
        </div>
      </div>
    </div>
  );
}
