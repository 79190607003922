import { Header } from '../Header'
import threePodGiff from '../../assets/gif/three_pods_loader.gif'
import styles from '../Loading/styles.module.css';
import leaderboardStyles from '../LeaderBoard/styles.module.css'

export function Loading() {
  const loadingData = {
    payload: {
      title: "Loading..."
    },
    status: {
      code: 200
    }
  }
  return (
    <div>
        <Header {...loadingData}></Header>
        <div className={leaderboardStyles.leaderboards}>
          <img className={styles.errorsHazzard1} alt="Loader" src={threePodGiff} />
        </div>
      </div>
    );
}
