import { ScoreMarkEnum } from '../../api/enums/leaderboard-enums';
import { BPLeaderboardPayload } from '../../api/interfaces/BPleaderboard-payload.interface';
import {
  getTitleCase,
  getTitleFromCamelCase
} from '../../utils/helpers/strings';
import styles from '../TableHeaders/styles.module.css';

interface tableHeadersProps {
  marks: ScoreMarkEnum[];
  payload: BPLeaderboardPayload;
}

export function TableHeaders(props: tableHeadersProps) {
  const { marks, payload } = props;

  const { title, subtitle } = payload;

  function convertMarkToHeader(mark: ScoreMarkEnum | string) {
    let header = '';
    switch (mark) {
      case ScoreMarkEnum.DURATION:
      case ScoreMarkEnum.HITS:
      case ScoreMarkEnum.REPS:
      case ScoreMarkEnum.WINS:
      case ScoreMarkEnum.STRIKES:
        header = getTitleCase(mark);
        break;
      case ScoreMarkEnum.REP_TIME:
        header = getTitleFromCamelCase(mark);
        break;
      case ScoreMarkEnum.TIMEOUT_MISSES:
        header = 'Misses';
        break;
      case ScoreMarkEnum.REACTION_TIME:
        header = 'Avg reaction';
        break;
      case ScoreMarkEnum.TOTAL_REACTION_TIMES:
        header = 'Test Time';
        break;
      case ScoreMarkEnum.LEVEL_SHUTTLE:
        if (
          title === 'Plyo Box Jump Test' ||
          subtitle === 'Plyo Box Jump Test'
        ) {
          header = 'Level / Jump';
        } else if (
          title === 'Plank and Reach Test' ||
          subtitle === 'Plank and Reach Test'
        ) {
          header = 'Level / Step';
        } else {
          header = 'Level / Shuttle';
        }
        break;
      case 'timed_playes':
        header = 'Times played';
        break;
      case 'empty_row':
        header = ' ';
        break;
    }
    return header;
  }

  return (
    <div className={styles.legend}>
      <div className={styles.margins}>
        <div className={styles.player}>
          <b className={styles.playerName}>Player name</b>
        </div>
        <div className={styles.stats}>
          {marks.map((mark) => (
            <div className={styles.div} key={`${mark}_header`}>
              <b className={styles.stat}>{convertMarkToHeader(mark)}</b>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
