import { useEffect, useRef } from "react";
import styles from "../styles.module.css";
import trophy from "../../../assets/svgs/vector-icon.svg";

import { PlayerName } from "../../PlayerName";
import { ScoreResult } from "../../ScoreResult";
import { ApplyAnimationProps, getAnimationDelay } from "./TableRowHelper";
import { BPLeaderboardItem } from "../../../api/interfaces/BPleaderboard-item.interface";

export interface TableItemProps extends ApplyAnimationProps {
  item: BPLeaderboardItem;
}

export function MakeHeaderRow(props: TableItemProps) {
  const { applyAnimation, item, animationConfiguration } = props;
  const elementRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const element = elementRef.current;
    if (element) {
      element.style.animation = applyAnimation ? animationConfiguration : '';
      element.style.animationDelay = applyAnimation ? getAnimationDelay(0) : '';
    }
  }, [animationConfiguration, applyAnimation]);

  return (
    <div className={styles.leaderboard1} ref={elementRef}>
      <div className={styles.leaderboardFirstRow}>
        <div className={styles.iconTrophy16px}>
          <img
            className={styles.vectorIcon1}
            alt="Trophy icon first place"
            src={trophy}
          />
        </div>
        <div className={styles.firstRow}>
          <PlayerName name={item.name} />
          <ScoreResult marks={props.marks} item={item} />
        </div>
      </div>
    </div>
  );
}
