import styles from '../Logos/styles.module.css';
import blazepod_big from '../../assets/svgs/blazepod-big.svg'

interface logosProps {
  rightLogo: string
}

export function Logos(props: logosProps) {
  const {
    rightLogo
  } = props

  return (
    <div className={styles.logos}>
      <div className={styles.blazepodContainer1}>
        <img className={styles.blazepodIcon} alt='BlazePod logo' src={blazepod_big} />
      </div>
      {rightLogo &&
        <div className={styles.companyLogo}>
            <img className={styles.image11Icon} alt="Custom logo" src={rightLogo} />
        </div>
        // : 
        // <div className={styles.emptySpacer}>
        //   </div>
      }
    </div>
  );
}